import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-4",attrs:{"elevation":"0"},on:{"click":_vm.openArticle}},[_c(VCardTitle,{staticClass:"headline"},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.article.Title))])]),_c(VCardText,[_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm._f("format")(_vm.article.PostedAt))+" ")]),_c('div',[_vm._v(_vm._s(_vm.body))])]),_c(VCardActions,{staticClass:"pb-6"},[_c(VSpacer),(_vm.canEdit)?_c(VBtn,{attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openArticle($event)}}},[_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.$t("latestNewsSections.latestNewsPage.read")))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-newspaper")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }